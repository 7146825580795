<template>
  <div>
    <div class="mt-5 text-center">Komentarze</div>
    <div>
      <div class="comment-box" v-for="(comms, key) in comments" :key="key">
        <div class="d-flex mb-2">
          <a href="#" class="comment-avatar">
            <img :src="comms.thumb_url ? plainUrl + comms.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="avatar">
          </a>
          <div class="comment-name">
            <a href="#" class="profile-link">{{comms.username}}</a>
            <a href="#" class="comment-date">{{comms.created_date}}</a>
          </div>
        </div>
        <div class="comment-content">
          <p>{{comms.content}}</p>
          <div @click="likeComment(comms.id)" class="comment-likes-wrapper">
            <div class="mr-1">{{comms.likes}}</div><i class="far fa-thumbs-up"></i>
          </div>
        </div>
      </div>
      <div class="mt-4">
      <div class="floating-msg-box">
        <div class="container">
        <div class="add-msg-box">
          <form action="">
            <div class="add-msg-group">
              <textarea v-model="message" name="" id="" cols="30" rows="1" placeholder="Dodaj komentarz..." class="autogrow"></textarea>
              <button @click.prevent="submit" type="submit"><i class="fas fa-paper-plane"></i></button>
            </div>
          </form>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewDetailsComments',
  props: {
    id: [String, Number],
    book_id: [String, Number]
  },
  data () {
    return {
      comments: [],
      message: '',
      actionCounter: 0
    }
  },
  created () {
    this.getComments()
  },
  watch: {
    actionCounter: function () {
      setTimeout(() => document.getElementById('review-detail-scroll-bottom').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' }), 100)
    }
  },
  methods: {
    submit () {
      this.message = this.message.trim()
      const getData = `content=${this.message}`
      this.$https('/book/' + this.book_id + '/comment/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.getComments()
        this.message = ''
        this.actionCounter++
      })
        .catch(error => {
          console.log('', error)
        })
    },
    getComments () {
      this.$https('/book/' + this.book_id + '/comment/' + this.id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.comments = data.response
          this.$emit('send-comments-amount', this.comments.length)
        })
        .catch(error => {
          console.log('', error)
        })
    },
    likeComment (id) {
      const getData = 'like=1'
      this.$https('/likes/comment/' + id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        },
        data: getData
      })
        .then(({ data }) => {
          this.getComments()
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .comment-date {
    font-size: 11px;
    font-weight: 200;
    margin-left: 10px;
    color: #000;
  }
  .comment-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .comment-likes-wrapper {
    width: 12%;
    display: flex;
    justify-content: space-between;
  }
  .floating-add-comment,
  .floating-msg-box {
    position: fixed;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #d5d5d5;
    padding: 7px 0;
    background: #f1f1f1;
    bottom: 60px;
    left: 0;

    .add-comment-box,
    .add-msg-box {
      $avatar-size: 0px;

      position: relative;
      padding-left: ($avatar-size + 5px);
      min-height: $avatar-size;

      &.full {
        padding-left: 0;
      }

      .add-comment-avatar,
      .add-msg-avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: $avatar-size;
        height: $avatar-size;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
        }
      }

      .add-comment-group,
      .add-msg-group {
        position: relative;

        button[type="submit"] {
          position: absolute;
          top: 0;
          right: 0;
          border: 0;
          background: none;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 10px 8px 5px;
          color: #d0d0d0;
          z-index: 2;
        }
        textarea {
          transition: height .2s ease;
          position: relative;
          z-index: 1;
          border: 1px solid #d0d0d0;
          padding: 8px 35px 8px 10px;
          border-radius: 15px;
          color: #d0d0d0;
          width: 100%;
          max-width: 100%;
          font-size: 11px;
          line-height: 15px;
          max-height: 120px;
          resize: none;

          &::-webkit-input-placeholder { /* Edge */
            color: #d0d0d0;
            opacity: 1;
          }
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #d0d0d0;
            opacity: 1;
          }
          &::placeholder {
            color: #d0d0d0;
            opacity: 1;
          }

          &:not(:placeholder-shown) {
            color: #000;

            & ~ button[type="submit"] {
              color: #000;
            }
          }
        }
      }
    }
}
</style>
