<template>
  <div>
    <TopMenuSubheadline title="recenzja" />
    <div v-if="!isReviewLoading && !isBookLoading">
      <div class="app-content-box bg-gray">
        <div class="wrapper-review">
          <div class="container">
            <div class="row mt-4">
              <div class="col-3">
                <router-link :to="{name: 'BooksDetails', params: {id: book.id}}">
                  <img :src="coverUrl + book.image" alt="" class="w-100">
                </router-link>
              </div>
              <div class="col-9 pl-0">
                <router-link :to="{name: 'BooksDetails', params: {id: book.id}}">
                  <h3 class="book-side-title-alt">{{book.tytul}}</h3>
                </router-link>
                <router-link :to="{name: 'Authors', params: {id: book.author_id}}">
                  <p class="book-side-author-alt">{{book.autor}}</p>
                </router-link>
              </div>
            </div>
            <div class="mt-3 mb-3 rating-wrapper">
              <star-rating :increment="0.5" active-color="#000"
                inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                :border-width='borderWidth' :animate='animate' :show-rating="false" :star-size=40
                :rounded-corners='rounded' :rating="rating" @rating-selected ="setRating">
              </star-rating>
            </div>
            <div class="text-center mt-3">
              <div>Ocena: {{avgRating}}</div>
              <div v-if="myRating">Twoja ocena: {{myRating}}</div>
            </div>
            <div class="book-description">
              <p>{{review.content}}</p>
            </div>
            <div class="icon-container bg-gray">
              <div @click="sendLikes" class="icon-wrapper"><div class="mr-1">{{review.likes}}</div><i class="far fa-thumbs-up"></i></div>
              <div class="icon-wrapper"><div class="mr-1">{{comments}}</div><i class="far fa-comments"></i></div>
              <a target="_blank"
              :href="'https://www.facebook.com/sharer/sharer.php?u='+ plainUrl +'/book/' + book_id + '/review/' + id"
              class="text-dark"><div class="icon-wrapper"><i class="far fa-paper-plane"></i></div></a>
            </div>
            <!-- <a href="#" class="btn-black-full w-100 mt-4">Dodaj recenzję</a> -->
            <div class="comments-container"><ReviewDetailsComments :id="id" :book_id="book_id" @send-comments-amount="commentsAmount" /></div>
          </div>
          </div>
          <div id="review-detail-scroll-bottom"></div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>

  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
import ReviewDetailsComments from '../components/ReviewDetailsComments'
import StarRating from 'vue-star-rating'
export default {
  name: 'ReviewDetails',
  props: {
    id: [Number, String],
    book_id: [Number, String]
  },
  components: {
    TopMenuSubheadline,
    Loader,
    ReviewDetailsComments,
    StarRating
  },
  data () {
    return {
      title: '',
      review: [],
      book: [],
      myRating: 0,
      avgRating: 0,
      comments: '',
      isReviewLoading: true,
      isBookLoading: true,
      rating: 0,
      borderWidth: 2,
      animate: true,
      rounded: true
    }
  },
  async created () {
    await this.getReviews()
    await this.getBook()
  },
  methods: {
    getBook () {
      this.$https('/book/' + this.book_id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.book = data.response.book
          this.myRating = this.book.myRating
          if (this.avgRating % 1 === 0) {
            this.avgRating = parseFloat(this.book.rating, 10)
          } else {
            this.avgRating = parseFloat(this.book.rating, 10)
            this.avgRating = this.avgRating.toFixed(1)
          }
          this.rating = parseFloat(this.book.rating, 10)
          this.isBookLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    setRating (rating) {
      const getData = `value=${rating}`
      this.$https('/rate/book/' + this.book_id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.getBook()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    commentsAmount (value) {
      this.comments = value
    },
    getReviews () {
      this.$https('/book/' + this.book_id + '/review/' + this.id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.review = data.response.rewiev
          this.isReviewLoading = false
        })
        .catch(error => {
          console.log('', error)
        })
    },
    sendLikes () {
      const getData = 'like=1'
      this.$https('/likes/review/' + this.id, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        },
        data: getData
      })
        .then(({ data }) => {
          this.getReviews()
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
#review-detail-scroll-bottom {
  background-color: transparent;
  width: 100%;
  height: 70px;
}
  .wrapper-review {
    padding-top: 20px;
    // padding-bottom: 40px;
  }
  .book-side-title-alt, .book-side-author-alt  {

    &:hover, &:active {
      text-decoration: underline;
    }
  }
  .icon-container {
    width: 100%;
    height: 30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .icon-wrapper {
      width: 12%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .fa-thumbs-up, .fa-comments, .fa-paper-plane {
    font-size: 22px;
  }
  }
  .comments-container {
    width: 100%;
  }
.rating-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  }
</style>
